<template>
  <v-container class="is-md py-12">
    <!-- header -->
    <v-row align="center" class="mb-3">
      <v-col cols="6">
        <v-btn
          plain small :ripple="false" text
          to="/items/search"
          class="unwidth px-0"
        >
          <v-icon class="mr-1">
            mdi-keyboard-backspace
          </v-icon>
          Back to items
        </v-btn>
      </v-col>

      <v-col cols="6" class="text-right">
        <v-dialog
          v-model="isDeleting"
          max-width="400"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              small text color="error"
              class="mr-3 "

              v-bind="attrs"
              v-on="on"
            >
              <v-icon small left class="mr-1">
                mdi-delete
              </v-icon>
              <span class="font-weight-bold">Delete</span>
            </v-btn>
          </template>
          <v-card color="grey600">
            <v-card-title class="text-h5">
              Are you sure?
            </v-card-title>
            <v-card-text v-if="item">
              <p class="text-body-2 mb-2">
                This will remove <b class="warning--text">{{ item.variants.length }} item variants</b> and all price data associated with them.
              </p>

              <p class="text-caption font-weight-semibold error--text mb-0">
                Any cases with this item will also be disabled automatically and have to be fixed manually.
              </p>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                text
                @click="isDeleting = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="error"
                text
                @click="onDelete"
              >
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-chip label color="primary--gradient" light>
          <span class="font-weight-semibold text-uppercase">Editing item</span>
        </v-chip>
      </v-col>
    </v-row>

    <!-- loaders -->
    <v-row v-if="loading.item || !formDataReady">
      <v-col cols="2">
        <v-skeleton-loader height="80" type="image" />
      </v-col>
      <v-col cols="5">
        <v-skeleton-loader height="80" type="image" />
      </v-col>
      <v-col cols="5">
        <v-skeleton-loader height="80" type="image" />
      </v-col>

      <v-col cols="12">
        <v-skeleton-loader class="mb-7" height="120" type="image" />
        <v-skeleton-loader class="mb-7" height="180" type="image" />
        <v-skeleton-loader class="mb-7" height="120" type="image" />
        <v-skeleton-loader type="image" />
      </v-col>
    </v-row>

    <v-row v-else-if="error">
      <v-col cols="12" md="6" offset-md="3">
        <v-alert type="error" color="red darken-4">
          {{ error }}
        </v-alert>
      </v-col>
    </v-row>

    <!-- item -->
    <ItemForm
      v-else
      :value="forms"
      :markets="markets"
      :rarities="rarities"
      :categories="categories"
      :loading="loading.submit"
      @submit="onSubmit"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import ItemForm from './components/ItemForm'

export default {
  metaInfo() {
    const { item, itemId } = this
    return {
      title: `Editing item: ${item?.name} - ${itemId}`,
    }
  },
  components: {
    ItemForm,
  },
  data() {
    return {
      loading: {
        item: false,
        data: false,
        submit: false,
      },
      isDeleting: false,
      rarities: [],
      categories: [],
      markets: [],
      item: null,
      error: null,
      forms: null,
    }
  },
  computed: {
    ...mapGetters({
      session: 'auth/session',
    }),
    itemId() {
      return this.item?._id ?? this.$route.params.id
    },
    formDataReady() {
      return !!this.markets.length && !!this.categories.length && !!this.rarities.length
    },
  },
  async created() {
    await this.fetchApiData(this.itemId)
    await this.getFormData()
  },
  methods: {
    async getFormData() {
      try {
        this.loading.data = true
        const [markets, categories, rarities] = await Promise.all([
          this.$socket.request('admin.markets.get'),
          this.$socket.request('admin.items.categories'),
          this.$socket.request('admin.items.rarities'),
        ])

        this.markets = markets
        this.categories = categories
        this.rarities = rarities
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.data = false
      }
    },
    async fetchApiData(itemId = this.itemId) {
      try {
        this.error = null
        this.loading.item = true
        const item = await this.$socket.request('admin.items.getId', itemId)

        this.item = item
        this.forms = {
          item: {
            _id: item._id,
            name: item.name,
            description: item.description,
            type: item.type,
            brand: item.brand,
            category: item.category?._id,
            canOrder: item.canOrder,
            canWithdraw: item.canWithdraw,
            canSwap: item.canSwap,
            canSell: item.canSell,
          },
          variants: item.variants.map(variant => ({
            _id: variant._id,
            name: variant.name,
            rarity: variant.rarity?._id,
            icon: variant.icon,
            prices: variant.prices.reduce((acc, price) => {
              acc[price.market] = price
              return acc
            }, {}),
            metaFields: variant.meta
              ? Object.entries(variant.meta).map(([key, value]) => ({ key, value }))
              : [],
          })),
        }
      } catch (error) {
        this.error = error.message
      } finally {
        this.loading.item = false
      }
    },
    async onSubmit(forms) {
      if (this.loading.submit) return
      this.loading.submit = true

      try {
        await this.$socket.request('admin.items.update', forms)

        this.$toast.success('Item updated successfully')
        this.$router.push('/items/search')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.submit = false
      }
    },
    async onDelete() {
      if (this.loading.submit) return
      this.loading.submit = true

      this.isDeleting = false

      try {
        await this.$socket.request('admin.items.delete', this.itemId)

        this.$toast.success('Item deleted successfully')
        this.$router.push('/items/search')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.submit = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
