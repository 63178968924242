<template>
  <div>
    <!-- general -->
    <v-card class="pa-4 mb-4" color="grey700">
      <h4 class="text-body-1 primary500--text font-weight-bold">
        General item data
      </h4>
      <p class="text-caption">
        This is the base item which stores generic item info.
      </p>

      <v-form @submit.prevent>
        <v-row>
          <v-col cols="12" md="6">
            <fieldset>
              <label class="text-body-2 d-block mb-1">Generic name</label>
              <v-text-field
                v-model="forms.item.name" filled dense color="primary500"
                placeholder="iPhone 13 (Blue)"
                hide-details
              />
            </fieldset>
          </v-col>

          <v-col cols="6" class="d-none d-md-block" />

          <v-col cols="12" md="4">
            <fieldset>
              <label class="text-body-2 d-block mb-1">Type</label>
              <v-combobox
                v-model="forms.item.type"
                :items="['object', 'wearable', 'digital']"
                filled clearable dense
                placeholder="object"
                hide-details
              />
            </fieldset>
          </v-col>

          <v-col cols="12" md="4">
            <fieldset>
              <label class="text-body-2 d-block mb-1">Brand</label>
              <v-text-field
                v-model="forms.item.brand" filled dense color="primary500"
                placeholder="Apple"
                hide-details
              />
            </fieldset>
          </v-col>

          <v-col cols="12" md="4">
            <fieldset>
              <label class="text-body-2 d-block mb-1">Category</label>
              <v-select
                v-model="forms.item.category"
                :items="categories"
                item-text="name"
                item-value="_id"
                filled dense
                placeholder="Tech"
                hide-details
              />
            </fieldset>
          </v-col>

          <v-col cols="12">
            <fieldset>
              <label class="text-body-2 d-block mb-1">Item description (md editor soon)</label>
              <v-textarea
                v-model="forms.item.description" hide-details dense color="primary500"
                placeholder="Generic description that fits all variants..." rows="4"
                filled auto-grow
              />
            </fieldset>
          </v-col>

          <v-col cols="12">
            <v-divider class="my-3" />
          </v-col>

          <v-col cols="12" md="6">
            <v-card color="grey500" class="px-4 py-2" @click="forms.item.canWithdraw = !forms.item.canWithdraw">
              <v-switch
                :input-value="forms.item.canWithdraw"
                color="primary500" class="pa-0 ma-0"
                inset hide-details
              >
                <template #label>
                  <div>
                    <h4 class="text-body-2 grey100--text">
                      Withdrawable
                    </h4>
                    <p class="text-caption mb-0">
                      Item can be withdrawn for crypto
                    </p>
                  </div>
                </template>
              </v-switch>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card color="grey500" class="px-4 py-2" @click="forms.item.canOrder = !forms.item.canOrder">
              <v-switch
                :input-value="forms.item.canOrder"
                color="primary500" class="pa-0 ma-0"
                inset hide-details
              >
                <template #label>
                  <div>
                    <h4 class="text-body-2 grey100--text">
                      Orderable
                    </h4>
                    <p class="text-caption mb-0">
                      Item can be shipped to user
                    </p>
                  </div>
                </template>
              </v-switch>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card color="grey500" class="px-4 py-2" @click="forms.item.canSell = !forms.item.canSell">
              <v-switch
                :input-value="forms.item.canSell"
                color="primary500" class="pa-0 ma-0"
                inset hide-details
              >
                <template #label>
                  <div>
                    <h4 class="text-body-2 grey100--text">
                      Sellable
                    </h4>
                    <p class="text-caption mb-0">
                      Item can be sold for balance
                    </p>
                  </div>
                </template>
              </v-switch>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card color="grey500" class="px-4 py-2" @click="forms.item.canSwap = !forms.item.canSwap">
              <v-switch
                :input-value="forms.item.canSwap"
                color="primary500" class="pa-0 ma-0"
                inset hide-details
              >
                <template #label>
                  <div>
                    <h4 class="text-body-2 grey100--text">
                      Exchangeable
                    </h4>
                    <p class="text-caption mb-0">
                      Item can be swapped for other items
                    </p>
                  </div>
                </template>
              </v-switch>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </v-card>

    <!-- variants -->
    <v-card class="pa-4 mb-8" color="grey700">
      <h4 class="text-body-1 primary500--text font-weight-bold">
        Item variants
      </h4>
      <p class="text-caption mb-1">
        These extend the base item and are what the user actually receives.
        Things like colors/sizes all have their own variants.
      </p>
      <p class="text-caption orange--text lh-1-3">
        <b>When to make a new item, not variant</b>: if an item has too many variant
        combinations like color AND size (blue - large, blue - medium, red - large...) it makes
        more sense to just create a new item called <em>Item (Blue)</em> with size variants.
      </p>

      <v-expansion-panels class="mb-4">
        <v-expansion-panel v-for="(variant, i) in forms.variants" :key="i">
          <v-expansion-panel-header color="grey600" class="font-weight-semibold">
            {{ variant.name }}
          </v-expansion-panel-header>
          <v-expansion-panel-content color="grey600">
            <v-form class="mb-4" @submit.prevent>
              <v-row dense>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" md="3">
                      <v-hover>
                        <template #default="{hover}">
                          <v-img class="grey400" :src="variant.icon" :aspect-ratio="1" contain>
                            <v-fade-transition>
                              <div v-if="!variant.icon || hover" class="full-height item-variant-icon">
                                <v-btn
                                  v-if="variant.icon"
                                  icon small absolute top
                                  right
                                  @click="variant.icon = null"
                                >
                                  <v-icon>
                                    mdi-close
                                  </v-icon>
                                </v-btn>

                                <FileDropArea
                                  :files="files"
                                  :loading="uploading"
                                  disable-hint
                                  accept="image/*"
                                  class="rounded-0 full-height"
                                  @submit="onVariantImageSubmitted($event, i)"
                                  @rejectedFiles="$toast.error('File type invalid')"
                                >
                                  <span class="mb-2">Drop file or</span>
                                </FileDropArea>
                              </div>
                            </v-fade-transition>
                          </v-img>
                        </template>
                      </v-hover>
                    </v-col>
                    <v-col cols="12" md="9">
                      <v-row>
                        <v-col cols="12" md="7">
                          <fieldset>
                            <label class="text-body-2 d-block mb-1">Item name</label>
                            <v-text-field
                              v-model="variant.name" filled dense color="primary500"
                              placeholder="iPhone 13 (Blue) 256GB"
                              hide-details
                            />
                          </fieldset>
                        </v-col>

                        <v-col cols="12" md="5">
                          <fieldset>
                            <label class="text-body-2 d-block mb-1">Rarity</label>
                            <v-select
                              v-model="variant.rarity"
                              :items="rarities"
                              item-text="name"
                              item-value="_id"
                              filled clearable dense
                              hide-details
                              placeholder="Rare"
                            />
                          </fieldset>
                        </v-col>

                        <v-col cols="12">
                          <fieldset>
                            <label class="text-body-2 d-block">Item icon url</label>
                            <p class="text-caption mb-2">
                              Please don't upload images that are too large, ~1000x1000px is usually sweet spot.
                            </p>
                            <v-text-field
                              :value="variant.icon" filled dense color="primary500"
                              placeholder="Icon url (read only)" readonly
                            />
                          </fieldset>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12">
                  <h4 class="text-body-2 primary500--text font-weight-bold">
                    Extra item data
                  </h4>
                  <p class="text-caption mb-1">
                    This depends on the item <b>type</b> you selected above.
                  </p>

                  <v-chip
                    v-for="key in availableTypeExtraKeys(variant)" :key="key" small link
                    class="mr-2" @click="onAddVariantExtra(i, key)"
                  >
                    <v-icon left small class="mr-1">
                      mdi-plus
                    </v-icon>
                    {{ key }}
                  </v-chip>

                  <v-chip small link class="mr-2" @click="onAddVariantExtra(i)">
                    <v-icon left small class="mr-1">
                      mdi-plus
                    </v-icon>
                    custom field
                  </v-chip>
                </v-col>

                <v-col cols="12">
                  <!-- vars -->
                  <div
                    v-for="(variable, index) in variant.metaFields" :key="index"
                    class="mb-3 d-flex"
                  >
                    <v-row dense>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="variable.key"
                          filled dense hide-details
                          placeholder="Key"
                        />
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-combobox
                          v-if="EXTRA_TYPE_ENUMS[variable.key]"
                          v-model="variable.value"
                          :items="EXTRA_TYPE_ENUMS[variable.key]"
                          item-text="name"
                          item-value="_id"
                          filled clearable dense
                          hide-details
                          placeholder="Value"
                        />
                        <v-text-field
                          v-else
                          v-model="variable.value"
                          filled dense hide-details
                          placeholder="Value"
                        />
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-btn
                          color="grey300" class="text-caption"
                          @click="variant.metaFields.splice(index, 1)"
                        >
                          <v-icon small class="mr-1">
                            mdi-close
                          </v-icon>
                          <span class="font-weight-bold">Remove</span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>

                <v-col cols="12">
                  <v-divider class="my-3" />
                </v-col>

                <v-col cols="12">
                  <h4 class="text-body-1 primary500--text font-weight-bold">
                    Item pricing
                  </h4>
                  <p class="text-caption mb-1">
                    Every market needs its own price, missing one disables it in that market.
                  </p>
                </v-col>

                <v-col cols="12">
                  <v-row>
                    <v-col v-for="market in markets" :key="market._id" cols="12">
                      <h5 class="text-caption primary500--text mb-2 font-weight-bold">
                        <span v-text="market.name" />
                      </h5>

                      <v-row>
                        <v-col cols="12" md="4">
                          <v-currency-field
                            v-model="variant.prices[market._id].price"
                            :options="{ currency: market.currency, allowNegative: false }"
                            :placeholder="`Item price in ${market.currency}`"
                            filled hide-details dense
                          >
                            <template #prepend-inner>
                              <v-chip
                                label small color="primary--gradient" light
                                class="mr-2 px-2 font-weight-bold text-uppercase"
                              >
                                {{ market.slug }}
                              </v-chip>
                            </template>
                          </v-currency-field>
                        </v-col>

                        <v-col cols="12" md="8">
                          <v-text-field
                            v-model="variant.prices[market._id].source" filled dense color="primary500"
                            placeholder="Source link (optional)" hide-details
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>

            <div class="text-right">
              <v-btn
                color="red" class="text-none" small
                @click="forms.variants.splice(i, 1)"
              >
                <v-icon class="text-body-1 mr-2">
                  mdi-delete
                </v-icon>
                Delete variant
              </v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <div class="text-right">
        <v-btn
          color="blue600 blue100--text" class="text-none"
          @click="onAddNewVariant"
        >
          <v-icon class="mr-2">
            mdi-plus
          </v-icon>
          Add new variant
        </v-btn>
      </div>
    </v-card>

    <div v-if="diff && !isNew">
      <h2 class="text-subtitle-1 text-uppercase primary500--text mb-2 font-weight-bold">
        Changes
      </h2>

      <v-divider class="mb-4" />

      <div v-for="(edit, i) in diff" :key="i" class="mb-2">
        <h4 class="text-body-1 mb-2 d-inline-flex align-center mr-2">
          <span class="px-2 py-1" :class="edit.classes">
            <v-icon
              class="text-caption"
              fixed-width
              v-text="edit.icon"
            />
          </span>
          <span class="grey600 grey100--text py-1 px-2">
            <span v-if="edit.path">{{ edit.path.join('.') }}</span>
            <span v-show="edit.index !== undefined" class="yellow--text">[{{ edit.index }}]</span>
          </span>
        </h4>

        <span v-if="edit.kind === 'A'">
          <span class="grey200--text">{{ edit.item.lhs || '(falsy)' }}</span>
          <v-icon class="mx-2 blue500--text">mdi-arrow-right-thin</v-icon>
          <span class="grey100--text">{{ edit.item.rhs || '(falsy)' }}</span>
        </span>

        <span v-else-if="edit.kind !== 'D'">
          <span class="grey200--text">{{ edit.lhs || '(falsy)' }}</span>
          <v-icon class="mx-2 blue500--text">mdi-arrow-right-thin</v-icon>
          <span class="grey100--text">{{ edit.rhs || '(falsy)' }}</span>
        </span>

        <span v-else class="grey200--text">removed</span>
      </div>
    </div>

    <div class="text-right">
      <v-btn
        v-if="diff && !isNew" text small class="mr-4"
        @click="onReset"
      >
        Reset changes
      </v-btn>

      <v-btn
        color="green--gradient" light
        large @click="$emit('submit', forms)"
      >
        <v-icon class="mr-2">
          mdi-check
        </v-icon>
        Submit
      </v-btn>
    </div>
  </div>
</template>

<script>
import diff from 'deep-diff'
import axios from 'axios'
import FileDropArea from '@/components/FileDropArea'

const DIFF_ICONS = {
  N: 'mdi-plus',
  D: 'mdi-minus-thick',
  E: 'mdi-pencil',
  A: 'mdi-layers-edit',
}

const DIFF_CLASSES = {
  N: 'green500--text',
  D: 'red--text red darken-5',
  E: 'blue200--text blue700',
  A: 'primary100--text primary700',
}
const ITEM_TYPE_EXTRAS = {
  wearable: ['size', 'color'],
  object: ['color'],
  digital: ['platform'],
}

const EXTRA_TYPE_ENUMS = {
  size: ['osfm', 'xs', 's', 'm', 'l', 'xl', 'xxl', 'xxxl', 'fucking fat'],
}

const VARIANT_FORM_DEFAULT = () => ({
  name: 'New variant',
  rarity: null,
  icon: null,
  prices: {},
  metaFields: [],
})

export default {
  components: {
    FileDropArea,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    markets: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
    rarities: {
      type: Array,
      default: () => [],
    },
    loading: Boolean,
    isNew: Boolean,
  },
  data() {
    return {
      uploading: false,
      files: [],
      forms: structuredClone(this.value),
    }
  },
  computed: {
    itemTypeExtraKeys() {
      return ITEM_TYPE_EXTRAS[this.forms.item.type] ?? []
    },
    EXTRA_TYPE_ENUMS() {
      return EXTRA_TYPE_ENUMS
    },
    diff() {
      const diffs = diff(this.value, this.forms)

      return diffs?.map(i => ({
        ...i,
        icon: DIFF_ICONS[i.kind],
        classes: DIFF_CLASSES[i.kind],
      }))
    },
  },
  watch: {
    files(files) {
      if (files.length) {
        this.files = []
      }
    },
    value(val) {
      this.forms = structuredClone(val)
    },
  },
  created() {
    // component should only be mounted once markets are loaded
    if (this.isNew) {
      this.onAddNewVariant()
    }
  },
  methods: {
    onAddNewVariant() {
      const variant = VARIANT_FORM_DEFAULT()

      for (const market of this.markets) {
        variant.prices[market._id] = {
          price: null,
          source: null,
        }
      }

      this.forms.variants.push(variant)
    },
    async onVariantImageSubmitted(files, variantIndex) {
      if (this.uploading) return

      const [file] = files
      if (!file) return

      this.uploading = true

      const variant = this.forms.variants[variantIndex]

      if (variant.icon) {
        // await this.deleteVariantIcon()
        variant.icon = null
      }

      try {
        const fd = new FormData()
        fd.append('file', file)
        fd.append('metadata', JSON.stringify({ test: true }))

        const { data: { response: resp } } = await axios.post(
          `${CONFIG.api.url}/acp/items/upload-image`,
          fd,
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )

        const url = resp.variants.find(i => i.includes('/original'))

        variant.icon = url
      } catch (error) {
        let err = error

        if (error.isAxiosError) {
          err = err.response.data.response
        }

        this.$toast.error(err.message)
      } finally {
        this.uploading = false
      }
    },
    availableTypeExtraKeys(variant) {
      if (!variant) return []

      const usedFields = variant.metaFields.map(i => i.key)
      return this.itemTypeExtraKeys.filter(i => !usedFields.includes(i))
    },
    onAddVariantExtra(index, key) {
      const variant = this.forms.variants[index]
      if (!variant) return

      variant.metaFields.push({
        key: key ?? 'newField',
        value: null,
      })
    },
    onReset() {
      this.forms = structuredClone(this.value)
    },
  },
}
</script>

<style lang="scss" scoped>
.item-variant-icon {
  background: rgba(black, 0.7);
}
</style>
